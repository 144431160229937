import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';

import { LangContext } from 'context/LangContext';
import usePartnersPage from 'data/queries/usePartnersPage';
import PartnershipPrograms from 'components/partners/partnershipPrograms';
import PartnershipCases from 'components/partners/partnershipCases';
import PartnershipStaff from 'components/partners/partnershipStaff';
import Ingress from 'components/partners/partnershipIngress';
import LinkBox from 'components/partners/partnershipLink';
import Banner from 'components/misc/Banner';

const Partners = () => {
    const { lang } = useContext(LangContext);

    const data = usePartnersPage();

    return (
        <>
            <Helmet>
                <meta property="og:title" content="Att vara partner till Hammarby Fotboll" />
                <meta property="og:site_name" content="Hammarby Fotboll" />
                <meta property="og:type" content="article" />
                <meta property="og:image" content="https://cdn.sanity.io/images/8k2hu6rb/production/fa4afcbd4a4063b20644702bc766136487f88e12-7892x4439.jpg?w=7892&h=4439&q=60&auto=format&dpr=1" />
                <meta property="og:description" content="A perfect match."/>
                <meta property="og:url" content="https://www.hammarbyfotboll.se/hammarby-partners" />
            </Helmet>
            <div>
                {data.bannerImage && data.bannerTitle && (
                    <Banner
                        image={data.bannerImage.image}
                        title={data.bannerTitle[lang.hreflang]}
                        description={data?.bannerDescription[lang.translationKey]?._rawContent}
                    />
                )}

                {data.ingress && data.ingress[lang.translationKey]?._rawContent &&(
                    <Ingress ingressContent={data.ingress[lang.translationKey]?._rawContent} />
                )}

                {data.linkBoxLink.slug.current && (
                    <LinkBox
                        header={data.linkBoxTitle[lang.hreflang]}
                        description={data.linkBoxDescription[lang.translationKey]._rawContent}
                        cta={data.linkBoxCta[lang.hreflang]}
                        slug={data.linkBoxLink.slug.current}
                    />
                )}

                {data.linkBoxLink.slug.current && (
                    <LinkBox
                        header="Boka eventpaket till Allsvenskan 2025"
                        description=""
                        cta="Eventpaket"
                        slug="/eventpaket"
                    />
                )}

                {data.partnershipPrograms.length !== 0 && (
                    <PartnershipPrograms
                        programs={data.partnershipPrograms}
                        formGdprMessage={data.formGdprMessage}
                        formSuccessMessage={data.formSuccessMessage}
                    />
                )}

                {data.partnershipCases.length !== 0 && (
                    <PartnershipCases
                        partners={data.partnershipCases}
                    />
                )}

                {data.hammarbyStaff.length !== 0 && (
                    <PartnershipStaff
                        members={data.hammarbyStaff}
                    />
                )}
            </div>
        </>
    );
};

export default Partners;
